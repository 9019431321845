import { SpaceStationMetadata } from './types';

const metadata: SpaceStationMetadata = {
  'andrómeda': {
    location: 'andrómeda',
    message: {
      asset: 'modals/Andromeda_Information_Card.jpg',
      note: "RECURSOS PARA EL RESCATE DISPONIBLES EN ESTA ESTACIÓN PARA RECOGIDA POR VÍA ESTELAR",
      technology: "Equipo de mantenimiento de alimentos",
      title: 'Equipo de mantenimiento de alimentos',
      paragraphs:[
        {text: 'Actualmente, el equipo de mantenimiento de alimentos está en otra misión y solo regresará a Andrómeda el Día 14.'}
      ],
      sideNote: "disponible el Día 14",
    }
  },
  'acuario': {
    location: 't3',
    message: {
      asset: 'modals/Aquarius_Information_Card.jpg',
      note: "RECURSOS PARA EL RESCATE DISPONIBLES EN ESTA ESTACIÓN PARA RECOGIDA POR VÍA ESTELAR",
      technology: "Equipo de mantenimiento hidráulico",
      title: 'COMIDA EN ORIÓN',
      paragraphs: [
        {text: 'Los sistemas de procesado de alimentos fueron destruidos en el momento del daño.'},
        {text: 'Las reservas de alimentos de emergencia se están agotando. Si esto no se repara, se empezarán a perder vidas el Día 24.'},
        {text: 'Un equipo de mantenimiento de alimentos estará disponible en Andrómeda.'},
      ],
    }
  },
  'boreal': {
    location: 't5',
    message: {
      asset: 'modals/Borealis_Information_Card.jpg',
      note: "RECURSOS PARA EL RESCATE DISPONIBLES EN ESTA ESTACIÓN PARA RECOGIDA POR VÍA ESTELAR",
      technology: "Equipo de mantenimiento de oxígeno",
      title: 'MANTENIMIENTO DE O2 EN ORIÓN',
      paragraphs: [
        {text: 'El equipo de mantenimiento de O2 puede restaurar de manera inmediata y permanente el O2 en Orión desde su llegada a la estación.'},
        {text: 'Sin embargo, no pueden salir de Boreal hasta que la tecnología de IA de Capricornio venga a reemplazarlos.'},
        {text: 'Salir con el equipo de mantenimiento de O2 antes de eso pondrá en riesgo a 200 científicos en Boreal.'},
      ],
    }
  },
  'capricornio': {
    location: 't4',
    message: {
      asset: 'modals/Capricorn_Information_Card.jpg',
      note: "RECURSOS PARA EL RESCATE DISPONIBLES EN ESTA ESTACIÓN PARA RECOGIDA POR VÍA ESTELAR",
      technology: "Tecnología de IA",
      title: 'HERIDOS EN ORIÓN',
      paragraphs: [
        {text: 'Se produjeron 3 heridos en el momento de la avería.'},
        {text: 'Los heridos están empeorando y los tres morirán el Día 25 si no reciben tratamiento médico avanzado.'},
        {text: 'Actualmente, el equipo médico está disponible en Casiopea.'},
      ]
    }
  },
  'casiopea': {
    location: 'casiopea',
    message: {
      asset: 'modals/Cassiopeia_Information_Card.jpg',
      note: "RECURSOS PARA EL RESCATE DISPONIBLES EN ESTA ESTACIÓN PARA RECOGIDA POR VÍA ESTELAR",
      technology: "Equipo médico",
      title: 'PROVISIÓN DE AGUA EN ORIÓN',
      paragraphs: [
        {text: 'El sistema de purificación de agua se apagó durante los daños.'},
        {text: 'Hay suficiente agua de reserva para mantener a los científicos vivos, pero solo hasta el Día 23. Se empezarán a perder vidas a no ser que los sistemas hidráulicos de la estación se arreglen de forma permanente.'},
        {text: 'Un equipo de mantenimiento hidráulico está disponible en Acuario.'},
      ]
    }
  },
  'orión': {
    location: 't2',
    message: {
      asset: 'modals/Orion_Information_Card.jpg',
      title: 'O2 EN ORIÓN ',
      paragraphs: [
        {text: 'Los científicos de la Estación Espacial Orión se están quedando rápidamente sin oxígeno.'},
        {text: 'Las celdas de reposición de O2 que están a bordo de la nave resolverán temporalmente el problema de suministro de oxígeno hasta el Día 21.'},
        {text: 'Se empezarán a perder vidas si una solución permanente de generación de oxígeno no se instala hasta el Día 21.'},
      ]
    }
  },
  'sagitario': {
    location: 'sagitario',
    message: {
      asset: 'modals/Sagittarius_Information_Card.jpg',
      title: 'AVERÍAS EN LA ESTACIÓN ESPACIAL ORIÓN',
      paragraphs: [
        {text: 'La principal fuente de generación de oxígeno de Orión se ha averiado gravemente.'},
        {text: 'Solo les quedan 6 días de suministro de oxígeno antes de que empiecen a perderse vidas.'},
        {text: 'Sin las reparaciones, la generación de oxígeno se apagará completamente en 21 días.'},
      ]
    }
  },
};

export default metadata;
