import React from 'react';
import EndGameStatsModal from './EndGameStatsModal';

import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal,
} from './modal';
import { StyledExtraModal } from './MessageModal';

export default () => {
  return <ModalBackground zIndex={1}>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          ¡Atención, tripulación Géminis!
        </Header>
        <Body>
          <p>Lamentablemente, el tiempo de la misión para rescatar a Orión se agotó. </p>
          <p>Nuestro equipo de control seguirá trabajando para salvar la estación espacial. Ahora es necesario que regresen al centro de control. </p>
          <p>Abróchense los cinturones: estamos a punto de teletransportarlos para que se encuentren con el comandante espacial para debatir sobre la misión. </p>
          <p>-Control en tierra </p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>    
    </BaseModalBackground>
  </ModalBackground>
}