import React from 'react';
import EndGameStatsModal from './EndGameStatsModal';

import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal
} from './modal';
import { StyledExtraModal } from './MessageModal';

export default () => {
  return <ModalBackground zIndex={1}>
    <BaseModalBackground>
      <StyledModal>
        <Header>
        ¡Misión abortada! 
        </Header>
        <Body>
          <p>Has abortado la misión. Sin tu ayuda, tememos que ha ocurrido lo peor en la Estación Espacial Orión. </p>
          <p>Avísale al comandante espacial para que venga y te dé instrucciones de lo que hay que hacer ahora. </p>
          <p>-Control en tierra</p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>    
    </BaseModalBackground>
  </ModalBackground>
}