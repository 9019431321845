import React from 'react';
import { useSelector } from '../redux-hook-adapters';
import styled from 'styled-components';
import { GameState, GameStatus, RescueResource } from '../../metadata/types';
import { formatTime } from '../../time-format-utils';

const Wrapper = styled.div`
  font-size: 14px;
`;

const Title = styled.h1`
  font-family: Orbitron;
  text-align: center;
`;

const ColumnHalf = styled.div`
  width: calc(50% - 10px);
  display: inline-block;
  padding: 5px;
`;

const DataPoint = styled.div`
  margin-bottom: 10px;
`;

const StatName = styled.div`
  display: inline-block;
  font-weight: bold;
  width: 60%;
  margin-right: 5px;
`;

const StatValue = styled.div`
  display: inline-block;
`;

export default () => {

  const state = useSelector((state: GameState) => state);
  const stats = state.gameStats;

  return <Wrapper>
    <Title>Estadísticas del juego</Title>
    <ColumnHalf>
      <DataPoint>
        <StatName>Volver a Sagitario</StatName>
        <StatValue>
          {
            state.status === GameStatus.MissionSucceeded ?
            `Día ${state.time}` : 'No terminó'
          }
        </StatValue>
      </DataPoint>
      <DataPoint>
        <StatName>Científicos salvados</StatName>
        <StatValue>
          {
            state.status === GameStatus.MissionSucceeded ?
            `${stats.scientistsRemaining} de 20` : 'Ninguno'
          }
        </StatValue>
      </DataPoint>
      <DataPoint>
        <StatName>Duración de la misión</StatName>
        <StatValue>
          {
            formatTime(state.endTime! - state.startTime + state.accumulatedTime)
          }
        </StatValue>
      </DataPoint>
      <DataPoint>
        <StatName>O2 temporal (requerido hasta el Día 6)</StatName>
        <StatValue>
          {
            stats.dropOffTimes[RescueResource.O2ReplacementCells] > -1 ?
            `Entregado el Día ${stats.dropOffTimes[RescueResource.O2ReplacementCells]}` : 'No fue reparado o conseguido'
          }
        </StatValue>
      </DataPoint>
    </ColumnHalf>
    <ColumnHalf>
      <DataPoint>
        <StatName>Oxígeno (requerido para el Día 21)</StatName>
        <StatValue>
          {
            stats.dropOffTimes[RescueResource.OxygenRepairTeam] > -1 ?
            `Entregado el Día ${stats.dropOffTimes[RescueResource.OxygenRepairTeam]}` : 'No fue reparado o conseguido'
          }
        </StatValue>
      </DataPoint>
      <DataPoint>
        <StatName>Agua (requerido para el Día 23)</StatName>
        <StatValue>
          {
            stats.dropOffTimes[RescueResource.WaterRepairTeam] > -1 ?
            `Entregado el Día ${stats.dropOffTimes[RescueResource.WaterRepairTeam]}` : 'No fue reparado o conseguido'
          }
        </StatValue>
      </DataPoint>
      <DataPoint>
        <StatName>Alimentos (requerido para el Día 24)</StatName>
        <StatValue>
          {
            stats.dropOffTimes[RescueResource.FoodRepairTeam] > -1 ?
            `Entregado el Día ${stats.dropOffTimes[RescueResource.FoodRepairTeam]}` : 'No fue reparado o conseguido'
          }
        </StatValue>
      </DataPoint>
      <DataPoint>
        <StatName>Equipo médico (requerido para el Día 25)</StatName>
        <StatValue>
          {
            stats.dropOffTimes[RescueResource.FoodRepairTeam] > -1 ?
            `Entregado el Día ${stats.dropOffTimes[RescueResource.FoodRepairTeam]}` : 'No fue reparado o conseguido'
          }
        </StatValue>
      </DataPoint>
    </ColumnHalf>
  </Wrapper>;
}