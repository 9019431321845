import React from 'react';
import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal
} from './modal';
import { StyledExtraModal } from './MessageModal';
import styled from 'styled-components';
import EndGameStatsModal from './EndGameStatsModal';

const SuccessModalBackground = styled(ModalBackground)`
  background-image: url(${process.env.PUBLIC_URL}/game_success_map.png);
  background-size: contain;
`;

export default () => {
  return <SuccessModalBackground>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          ¡Bienvenidos de regreso a Sagitario! 
        </Header>
        <Body>
          <p>¡Estamos emocionados al escuchar las historias de esta exitosa misión!</p>
          <p>Cuando estén listos, comuníquense con el comandante espacial para que venga a felicitarlos personalmente.</p>
          <p>¡Gracias por todo lo que hicieron para rescatar a Orión!</p>
          <p>-Control en tierra </p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>      
    </BaseModalBackground>
  </SuccessModalBackground>;
}